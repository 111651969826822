<template>
  <v-dialog v-model="visualizza" transition="dialog-bottom-transition" :overlay="false" max-width="900" scrollable>
    <v-card>
      <v-toolbar class="grey lighten-3" card>
        <v-toolbar-title>
          Aggiungi componente
        </v-toolbar-title>
        <v-spacer />
        <v-btn icon @click.stop="visualizza = false"><v-icon>close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text>
        <v-flex>
          <v-layout>
            <v-flex xs6>
              <v-select
                class="ma-2"
                label="Componente"
                :items="componenti"
                item-text="descrizione"
                item-value="_id"
                v-model="ricerca.componente_id" />
            </v-flex>
            <v-flex xs6>
              <v-text-field
                class="ma-2"
                label="Codice"
                v-model="ricerca.codice" />
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12 class="pa-2">
              <v-btn
                class="ml-0"
                color="primary"
                @click.native="cerca()">
                Filtra
              </v-btn>
              <v-btn
                @click="reset_cerca()">
                Ripristina
              </v-btn>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-form ref="formArrivi">
          <v-container fluid>
            <template v-for="arrivo in arrivi">
              <v-list :key="'arrivo-'+arrivo._id" dense>
                <v-list-tile avatar :key="'tile-'+arrivo._id">
                  <v-list-tile-content>
                    <v-list-tile-title class="text-capitalize" v-text="arrivo.descrizione + ' - ' + arrivo.codice" />
                    <v-list-tile-sub-title v-text="arrivo.note"></v-list-tile-sub-title>
                  </v-list-tile-content>
                  <v-text-field
                    label="Quantità"
                    v-model="arrivo.qta"
                    :suffix="'/'+(arrivo.qta_in - arrivo.qta_out)"
                    :hint="!arrivo.qta || arrivo.qta < (arrivo.qta_in - arrivo.qta_out) ? '' : 'ATTENZIONE! Quantità non disponibile'" />
                    <!-- :rules="[$rules.ngt(arrivo.qta_in - arrivo.qta_out), $rules.numeric, $rules.maxlength(7)]" /> -->
                </v-list-tile>
              </v-list>
            </template>
          </v-container>
        </v-form>
        <v-btn color="primary" @click="ins()" v-if="arrivi && arrivi.length">Aggiungi</v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { $EventBus } from '@/eventBus'

export default {
  data: () => ({
    arrivi: [],
    componenti: [],
    visualizza: false,
    cliente: null,
    clienti: [],
    ricerca: { }
  }),
  props: {
    inserisci: Function,
    fornitori: Array
  },
  methods: {
    ins () {
      let selected = this.arrivi.filter(a => !!a.qta)
      if (selected.length) {
        this.inserisci(selected)
        this.visualizza = false
      }
    },
    show () {
      this.visualizza = true
      this.reset_cerca()
      this.getComponenti()
    },
    hide () {
      this.visualizza = false
    },
    cerca (id) {
      this.$set(this.ricerca, 'ordine', this.$route.params.id)
      this.$plsqlReadAll('arrivo', this.ricerca)
        .then(response => {
          this.arrivi = response.data.items
        })
        .catch(err => {
          console.log('errore client', err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore lettura arrivi' })
        })
    },
    reset_cerca () {
      this.arrivi = []
      this.$set(this.ricerca, 'codice', null)
      this.$set(this.ricerca, 'componente_id', null)
    },
    getComponenti () {
      this.$plsqlMethod('arrivo', 'get_componenti', { })
        .then(response => {
          this.componenti = response.data
        })
        .catch(err => {
          console.log(err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore lettura componenti' })
        })
    }
  }
}
</script>
<style scoped>
  .v-list__group {
    border-bottom: 1px solid rgba(0,0,0,0.12);
  }
  .rowWrapper {
    height: auto;
    line-height: inherit;
    padding: 10px 0;
  }
  .rowWrapper-row {
    height: 90px;
    margin-top: 0!important;
  }
</style>
