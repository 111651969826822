<template>
  <section>
    <v-list two-line v-if="componenti.length">
      <template v-for="(componente, index) in componenti">
        <v-list-tile
          @click.stop="openCompDialog(componente)"
          :key="'tile-'+componente.arrivo_componente_id">
          <v-list-tile-content>
            <v-list-tile-title>
              <span class="text-uppercase">{{componente.codice}}</span>
              - {{componente.descrizione}}
            </v-list-tile-title>
            <v-list-tile-sub-title v-text="componente.note" />
          </v-list-tile-content>
          <v-list-tile-action>
            <v-list-tile-action-text v-text="'Qtà '+componente.qta" />
          </v-list-tile-action>
        </v-list-tile>
        <v-divider :key="componente.arrivo_componente_id" v-if="index < componenti.length - 1" />
      </template>
    </v-list>
    <v-tooltip left v-if="!readOnly()">
      <v-btn
        slot="activator"
        color="pink darken-1"
        dark fab fixed right bottom
        @click.stop="openCompArriviDialog()">
        <v-icon>add</v-icon>
      </v-btn>
      <span>Aggiungi componente</span>
    </v-tooltip>
    <v-alert
      outline=""
      color="info"
      value="true"
      v-if="!componenti.length">
      Nessun componente trovato
    </v-alert>
    <!-- modale componente -->
    <v-dialog
      v-model="showComp"
      transition="dialog-bottom-transition"
      :overlay="false"
      max-width="900">
      <v-card>
        <v-toolbar class="grey lighten-3" card>
          <v-toolbar-title>{{ modalTitle }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click.stop="showComp=false"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="formComponente" v-model="validFormComponente" lazy-validation>
            <v-container fluid>
              <v-layout row wrap>
                <v-flex xs12 lg6>
                  <v-layout row wrap>
                    <v-flex xs12>
                      <v-select
                        :items="allComponents"
                        :disabled="!componente.nuovo || readOnly()"
                        item-text="descrizione"
                        item-value="_id"
                        label="Componente"
                        v-model="componente.componente_id"
                        required />
                      <v-text-field
                        label="Codice"
                        v-model="componente.codice"
                        :disabled="!componente.nuovo || readOnly()"
                        :rules="[$rules.required, $rules.maxlength(20)]" />
                      <v-text-field
                        label="Quantità"
                        v-model="componente.qta"
                        :disabled="readOnly()"
                        :rules="[$rules.required, $rules.numeric, $rules.maxlength(7)]" />
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
              <v-layout row wrap>
                <v-flex xs12 lg6>
                  <v-btn
                    class="ml-0"
                    color="primary"
                    :disabled="!validFormComponente || readOnly()"
                    @click.native="save">
                    Salva
                  </v-btn>
                  <v-btn
                    color="error"
                    v-if="!componente.nuovo && !readOnly()"
                    @click="showRemoveComp(componente)">
                    Elimina componente
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- conferma elimina componente -->
    <modal-confirm ref="confirm" />
    <componenti-arrivi v-bind:inserisci="saveComponentiArrivi" v-bind:fornitori="fornitori" ref="componentiArrivi" />
  </section>
</template>

<script>
import { $EventBus } from '@/eventBus'
import _clone from 'lodash/clone'
import componentiArrivi from '@/components/produzione/ordini/OrdineComponentiArrivi'
import modalConfirm from '@/components/common/ModalConfirm'

export default {
  props: {
    ordine: Object
  },
  data: () => ({
    allComponents: [],
    componenti: [],
    componente: {},
    fornitori: [],
    fornitoriArrivi: [],
    allFornitori: [],
    arrivi: [],
    showComp: false,
    showArrivi: false,
    validFormComponente: false,
    validFormArrivi: false,
    fab: false,
    hover: false
  }),
  computed: {
    modalTitle () {
      return this.componente.nuovo ? 'Nuovo componente' : 'Modifica componente'
    }
  },
  components: {
    'componenti-arrivi': componentiArrivi,
    'modal-confirm': modalConfirm
  },
  methods: {
    getComponenti () {
      this.$plsqlMethod('arrivo', 'get_componenti', { })
        .then(response => { this.allComponents = response.data })
        .catch(err => {
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
        })
      this.$plsqlMethod('ordine', 'get_componenti', { id: this.ordine._id })
        .then(response => { this.componenti = response.data })
        .catch(err => {
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
        })
    },
    save () {
      if (this.$refs.formComponente.validate()) {
        this.showComp = false
        this.$plsqlMethod('ordine', 'save_componente', this.componente)
          .then(response => {
            $EventBus.$emit('message', {
              type: 'success',
              text: 'Componente salvato'
            })
            this.getComponenti()
          })
          .catch(err => {
            if (err.data.errCode === 'ORA-00001') {
              $EventBus.$emit('message', { type: 'error', text: 'Questo componente è già stato associato all\'Ordine! Correggere e riprovare.' })
            } else {
              $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
            }
          })
      }
    },
    showRemoveComp (c) {
      this.componente = _clone(c)
      this.$refs.confirm.show({
        modalTitle: 'Sicuro?',
        modalText: 'Stai per cancellare il componente <strong>' + c.descrizione + '</strong>.<p><strong>ATTENZIONE</strong>: verranno cancellati anche eventuali arrivi associati al componente.</p>',
        modalButtonOk: 'Elimina',
        modalFunctionOk: this.removeComp,
        modalObj: c
      })
    },
    removeComp () {
      this.$plsqlMethod('ordine', 'delete_componente', { ordine: this.ordine._id, id: this.componente.arrivo_componente_id })
        .then(response => {
          $EventBus.$emit('message', { type: 'success', text: 'Componente eliminato' })
          this.$refs.confirm.hide()
          this.showComp = false
          this.getComponenti()
        })
        .catch(err => {
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
        })
    },
    openCompDialog (componente) {
      this.componente = componente ? _clone(componente) : { ordine_id: this.ordine._id, nuovo: 1 }
      if (!this.$refs.confirm.status()) this.showComp = true
      if (!componente) this.$refs.formComponente.reset()
    },
    openCompArriviDialog (componente) {
      this.$refs.componentiArrivi.show()
    },
    saveComponentiArrivi (selected) {
      this.$plsqlMethod('ordine', 'save_arrivi_produzione', { ordine: this.ordine._id, arrivi: selected })
        .then(response => {
          this.getComponenti()
          $EventBus.$emit('message', { type: 'success', text: 'Arrivi salvati' })
          this.showArrivi = false
        })
        .catch(err => {
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
        })
    },
    readOnly () {
      return (!this.ordine.non_spedito)
    },
    getFornitori () {
      this.$plsqlReadAll('fornitore', { rows4page: 1000 })
        .then(response => {
          this.fornitori = response.data.items.filter((el) => {
            return el.attivo
          })
          this.allFornitori = response.data.items
        })
        .catch(err => {
          $EventBus.$emit('message', { type: 'error', text: err.data.errMessage })
        })
    }
  },
  mounted () {
    this.getComponenti()
    this.getFornitori()
  }
}
</script>

<style>
  .fix-speed-dial.v-speed-dial--direction-top .v-speed-dial__list {
    left: -1em!important;
    bottom: 150%!important;
  }
</style>
